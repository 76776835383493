import React, { useState } from "react";
import HeroBgAnimation from "../HeroBgAnimation";
import {
  HeroContainer,
  HeroBg,
  HeroLeftContainer,
  Img,
  HeroRightContainer,
  HeroInnerContainer,
  TextLoop,
  Title,
  Span,
  SubTitle,
  SFXButton,
} from "./HeroStyle";
import HeroImg from "../../images/avatar.jpg";
import farkasphoto from "../../images/farkas.jpg"
import Typewriter from "typewriter-effect";
import { Bio } from "../../data/constants";
import useSound from "use-sound";
import music from "../About/music.mp3";
import music2 from "../About/music2.mp3";
import music3 from "../About/music3.mp3";
import music4 from "../About/music4.mp3";
import sfx from "../About/wolf.mp3";
import { Buttoncontainer } from "./HeroStyle";

const musicOptions = [music, music2, music3, music4];

const randomMusicIndex = Math.floor(Math.random() * musicOptions.length);
const selectedMusic = musicOptions[randomMusicIndex];


const HeroSection = () => {
  const [playMusic, { pause: pauseMusic, isPlaying: isMusicPlaying }] = useSound(selectedMusic,{ volume: 0.25,loop: true });
  const [playSFX, { pause: pauseSFX, isPlaying: isSFXPlaying }] = useSound(sfx,{ volume: 0.25, loop: true  } );

  const [ismusicPlaying, setIsmusicPlaying] = useState(false);
  const [issfxPlaying, setIssfxPlaying] = useState(false);

  const toggleMusic = () => {
    if (ismusicPlaying) {
      pauseMusic();
    } else {
      playMusic();
    }
    setIsmusicPlaying(!ismusicPlaying);
  };

  const toggleSFX = () => {
    if (issfxPlaying) {
      pauseSFX();
    } else {
      playSFX();
    }
    setIssfxPlaying(!issfxPlaying);
  };

  return (
    <div id="about">
      <HeroContainer>
        <HeroBg>
          <HeroBgAnimation />
        </HeroBg>
        <HeroInnerContainer>
          <HeroLeftContainer id="Left">
            <Title>
              Hi, I am <br /> {Bio.name}
            </Title>
            <TextLoop>
              <Span>
                Sound Design
                <Typewriter
                  options={{
                    strings: Bio.roles,
                    autoStart: true,
                    loop: true,
                  }}
                />
              </Span>
            </TextLoop>
            <SubTitle>{Bio.description}</SubTitle>
          </HeroLeftContainer>
          <HeroRightContainer id="Right">
            <Img src={HeroImg} alt="hero-image" />
          </HeroRightContainer>
          <Buttoncontainer id="Right">
            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end",paddingBottom:"2%" }}>
              <SFXButton onClick={toggleMusic}>
                {ismusicPlaying ? "Audio OFF" : "Audio ON"}
              </SFXButton>
            </div>
            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
              <SFXButton onClick={toggleSFX}>
                {issfxPlaying ? "Pause SFX" : "Play SFX"}
                {issfxPlaying && <Title></Title> }
              </SFXButton>
            </div>
          </Buttoncontainer>
        </HeroInnerContainer>
      </HeroContainer>
    </div>
  );
};

export default HeroSection;
